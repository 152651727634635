<template>
    <div class="classify_add_eidt">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="500px" :close-on-click-modal='false' @close="cancelBtn">
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="main_info">
                <div class="info_text">
                    <div class="t_left"><span>*</span>文本名称</div>
                    <div class="t_right">
                        <el-input v-model="info.name" maxlength="10" :clearable="true" placeholder="请输入文本名称"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left" style="padding-bottom: 100px;"><span>*</span>文本内容</div>
                    <div class="t_right">
                        <el-input v-model="info.content" type="textarea" rows="5" placeholder="请输入文本内容" maxlength="200" show-word-limit>
                        </el-input>

                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>分类</div>
                    <div class="t_right">
                        <el-select v-model="info.category_id" :clearable="true" placeholder="请选择分类">
                            <el-option v-for="item in list" :key="item.value" :label="item.label" :value="numFuntion(item.value)">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>状态</div>
                    <div class="t_right">
                        <el-switch v-model="info.status" :active-value="1" :inactive-value="2"></el-switch>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
let that
export default {
    props: {
        list: {
            type: Array,
            defaul: []
        }
    },
    data() {
        return {
            title: '',
            dialogVisible: false,
            // 
            info: {
                name: '',
                category_id: '',
                content: '',
                txt_num: '',
                status: 2,
            },
            // 
            subLoading: false
        };
    },

    computed: {},

    watch: {},

    created() {
        that = this
    },

    mounted() { },

    methods: {
        // 打开
        openDialog(info = '') {
            if (info) {
                that.title = '编辑文本'
                that.info = info
            } else {
                that.title = '添加文本'
                that.info = {
                    name: '',
                    category_id: '',
                    content: '',
                    txt_num: '',
                    status: 2,
                }
            }
            that.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            that.info = {
                name: '',
                category_id: '',
                content: '',
                txt_num: '',
                status: 2,
            }
            that.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            var obj = JSON.parse(JSON.stringify(that.info))
            if (obj.name == '') {
                that.$warMsg('请输文本名称')
            } else if (obj.content == '') {
                that.$warMsg('请输入文本内容')
            } else if (obj.category_id == '') {
                that.$warMsg('请选择分类')
            } else if (obj.id) {
                // 编辑
                that.subLoading = true
                var params = {
                    name: this.info.name,
                    category_id: this.info.category_id,
                    content: this.info.content,
                    txt_num: this.info.content.length,
                    status: this.info.status,
                }
                that.$materialApi.textListEdit(params, obj.id).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else {
                // 添加
                that.subLoading = true
                var params = {
                    name: this.info.name,
                    category_id: this.info.category_id,
                    content: this.info.content,
                    txt_num: this.info.content.length,
                    status: this.info.status,
                }
                that.$materialApi.textListAdd(params).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 转换数字类型
        numFuntion(id) {
            return id * 1
        }
    }
}
</script>
<style lang='scss' scoped>
.classify_add_eidt {
    .main_info {
        width: 100%;
        .info_text {
            width: 100%;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .t_left {
                // width: 80px;
                padding-right: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-align: right;
                span {
                    color: #e51d41;
                }
            }
            .t_right {
                position: relative;
                width: 360px;
                .el-input {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }
                ::v-deep .el-textarea {
                    width: 100%;
                    .el-textarea__inner {
                        padding-bottom: 20px;
                    }
                }
                .input_btn {
                    position: absolute;
                    bottom: 5px;
                    left: 10px;
                    padding: 0px;
                    background: #fff;
                }
            }
        }
        .info_text:last-child {
            padding-bottom: 0;
        }
    }
}
</style>